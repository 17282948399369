import React from 'react'

import Layout from '../Layout'
import SEO from '../components/SEO'

import AsideImage from '../components/AsideImage/AsideImage'

const NotFoundPage = () => (
  <Layout header="404" asideContent={[<AsideImage key="asideImage" />]}>
    <SEO title="404 | Gabinet Stomatologiczny Glossy Dental | Stomatolog Dentysta Nadarzyn" />
    <h2>Podana strona nie istnieje</h2>
    <p>
      Niestety, wpisałeś zły adres url, albo strona została usunięta. Spóbuj
      inny adres lub
    </p>
  </Layout>
)

export default NotFoundPage
